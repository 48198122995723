import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { PreviousRoute } from 'mobile-web/lib/routing';
import BasketProductModel from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import VendorService from 'mobile-web/services/vendor';

export type ProductsRouteModel = {
  basketProduct?: BasketProductModel;
  displayInModal: boolean;
  pageTitle: string;
  previousRoute?: PreviousRoute;
  product: ProductModel;
  clickFrom?: ProductClickFrom;
};

export default class ProductIndexRoute extends Route {
  @service vendor!: VendorService;
  @service router!: RouterService;
  @service store!: DS.Store;

  async model(params: { product_id: number }, transition: Transition): Promise<ProductsRouteModel> {
    const vendorSlug = (this.paramsFor('menu.vendor') as Dict<string>).vendor_slug;
    const previousRoute: PreviousRoute = { label: '', route: '', models: [] };

    const product = await this.store.findRecord('product', params.product_id);

    const category = product.category;

    const clickFrom = transition.to.queryParams.clickFrom as ProductClickFrom;

    if (
      this.router.currentRoute?.name === 'menu.category' &&
      clickFrom === ProductClickFrom.RecentItem &&
      product.vendor?.id
    ) {
      previousRoute.label = 'Category';
      previousRoute.route = 'menu.category';
      previousRoute.models = [vendorSlug, `recent-items-id_${product.vendor.id}`];
    } else if (
      this.router.currentRoute?.name === 'menu.category' &&
      clickFrom === ProductClickFrom.MostOrdered &&
      product.vendor?.id
    ) {
      previousRoute.label = 'Category';
      previousRoute.route = 'menu.category';
      previousRoute.models = [vendorSlug, `most-ordered-id_${product.vendor.id}`];
    } else if (
      this.router.currentRoute?.name === 'menu.category' &&
      clickFrom === ProductClickFrom.ForYouCrossSell &&
      product.vendor?.id
    ) {
      previousRoute.label = 'Category';
      previousRoute.route = 'menu.category';
      previousRoute.models = [vendorSlug, `for-you-id_${product.vendor.id}`];
    } else if (this.router.currentRoute?.name === 'menu.category' && category) {
      previousRoute.label = 'Category';
      previousRoute.route = 'menu.category';
      previousRoute.models = [vendorSlug, category.get('id')];
    } else {
      previousRoute.label = 'Menu';
      previousRoute.route = 'menu.vendor';
      previousRoute.models = [vendorSlug];
    }

    const displayInModal = !product.hasChoices;

    return {
      displayInModal,
      pageTitle: product.name,
      previousRoute,
      product,
      clickFrom,
    };
  }
}
