import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import BasketProductModel from 'mobile-web/models/basket-product';
import { AddToCartMethod } from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import FocusManagerService, { CategoryName } from 'mobile-web/services/focus-manager';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import NotificationsService, { NotificationType } from 'mobile-web/services/notifications';
import OrderCriteriaService from 'mobile-web/services/order-criteria';

import style from './index.m.scss';

interface Args {
  // Required arguments
  basketProduct: BasketProductModel;
  displayInModal: boolean;
  isLoading: boolean;

  // Optional arguments
  onSave?: Action;
  clickFrom?: ProductClickFrom;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class ProductCustomizationFooter extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service focusManager!: FocusManagerService;
  @service intl!: IntlService;
  @service store!: DS.Store;
  @service router!: RouterService;
  @service notifications!: NotificationsService;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service orderCriteria!: OrderCriteriaService;

  // Untracked properties
  style = style;

  /**
   * keeping track of `isNew` so that "Add" button doesn't
   * switch to "Update" after `args.basketProduct` gets saved and
   * is no longer `isNew`.
   */
  isNew = this.args.basketProduct.get('isNew');
  previousQuantity =
    this.args.basketProduct.quantity > 0 ? this.args.basketProduct.quantity : undefined;

  // Tracked properties

  // Getters and setters
  get showPrice() {
    return !this.isLoading && this.channel.settings?.showProductPriceTicker;
  }

  get price() {
    return this.args.basketProduct.estimatedUnitCost * this.args.basketProduct.quantity;
  }

  get isLoading() {
    return this.args.isLoading || this.args.basketProduct.saveTask.isRunning;
  }

  get isDisabled() {
    return this.isLoading || !this.args.basketProduct.canSubmit;
  }

  get hasError() {
    return !this.isLoading && !this.args.basketProduct.canSubmit;
  }

  // Lifecycle methods

  // Other methods
  goToError() {
    if (this.hasError) {
      this.focusManager.focusFirst(CategoryName.OptionGroupError);
    }
  }

  // Tasks

  // Actions and helpers
  @action
  addToCart() {
    if (this.isDisabled) {
      this.goToError();
      return;
    }

    if (this.groupOrder.isLocked) {
      this.groupOrder.showGroupOrderLockedModal();
      return;
    }

    const basketProduct = this.args.basketProduct;

    const isNew = basketProduct.get('isNew');

    const clickFrom = this.args.clickFrom;

    let eventName: AddToCartMethod = 'Product Customize';
    if (this.args.displayInModal) {
      eventName = 'Zero Modifiers Product Modal';
    } else if (clickFrom === ProductClickFrom.CartUpsell) {
      eventName = 'Upsell';
    }

    basketProduct.upsell = clickFrom === ProductClickFrom.CartUpsell;

    basketProduct.saveTask.perform({
      eventName,
      clickFrom,
      onSuccess: () => {
        this.args.onSave?.();

        const message = this.intl.t(
          isNew ? 'mwc.notifications.added' : 'mwc.notifications.updated',
          { quantity: basketProduct.quantity }
        );
        let type = isNew ? NotificationType.ProductAdded : NotificationType.ProductUpdated;

        if (!isNew && !this.basket.isOpen) {
          type = NotificationType.ProductUpdatedOutsideBasket;
        }

        this.notifications.success({
          message,
          type,
        });

        const quantityRemoved =
          !isNew &&
          this.previousQuantity !== undefined &&
          this.previousQuantity > basketProduct.quantity;
        this.globalEvents.trigger(
          quantityRemoved ? GlobalEventName.RemoveFromCart : GlobalEventName.AddToCart,
          basketProduct.serializeForGlobalData(isNew ? undefined : this.previousQuantity)
        );

        if (this.orderCriteria.isMissingDeliveryAddress()) {
          this.orderCriteria.openModal({
            componentNameInitiatedModal: 'Product Customization Footer',
          });
        } else if (clickFrom === ProductClickFrom.CartUpsell) {
          this.basket.openAndScrollIntoView(basketProduct.id);
        }
      },
    });
  }

  @action
  updateQuantity(val: number) {
    this.args.basketProduct.quantity = val;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'product-customization/footer': typeof ProductCustomizationFooter;
  }
}
