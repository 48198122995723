import { Code } from 'mobile-web/lib/country';
import Method, { Variant } from 'mobile-web/lib/payment/method';
import isSome from 'mobile-web/lib/utilities/is-some';
import BillingScheme from 'mobile-web/models/billing-scheme';

// \platform\MoboLogic\src\Data Structures\Enums.cs
export enum Provider {
  CreditCard = 'CreditCard', // card, not a gift card
  PassThrough = 'PassThrough', // Cash
  PaytronixCard = 'PaytronixCard', // gift card
  Chockstone = 'Chockstone', // gift card
  RadiantGiftCard = 'RadiantGiftCard', // gift card
  LevelUp = 'LevelUp', // membership only
  SvsGiftCard = 'SvsGiftCard', // gift card
  ElavonGiftCard = 'ElavonGiftCard', // gift card
  MockGiftCard = 'MockGiftCard', // gift card
  GiftCard = 'GiftCard', // gift card
  Prepaid = 'Prepaid', // gift card
  DigitalWallet = 'DigitalWallet', // card
  CompCard = 'CompCard', // gift card
  BrandedCard = 'BrandedCard', // branded card
  HouseAccount = 'HouseAccount',

  // Obsolete but may still exist in the database
  PayPal = 'PayPal', // gift card
  PayPalMIS = 'PayPalMIS', // gift card
}

const CARD_TYPES: Provider[] = [
  Provider.CreditCard,
  Provider.PaytronixCard,
  Provider.RadiantGiftCard,
  Provider.SvsGiftCard,
  Provider.ElavonGiftCard,
  Provider.MockGiftCard,
  Provider.GiftCard,
  Provider.DigitalWallet,
  Provider.BrandedCard,
];

const NON_GIFT_CARD_TYPES: Provider[] = [
  Provider.CreditCard,
  Provider.PassThrough,
  Provider.LevelUp,
  Provider.DigitalWallet,
  Provider.BrandedCard,
];

/** Providers with methods that cannot include tip */
const NO_TIP_TYPES: Provider[] = [Provider.PassThrough, Provider.CompCard];

export const isCard = (provider: Provider): boolean => CARD_TYPES.includes(provider);

export const isNonGiftCard = (provider: Provider): boolean =>
  NON_GIFT_CARD_TYPES.includes(provider);

export const isTipType = (scheme: BillingScheme): boolean =>
  !NO_TIP_TYPES.includes(scheme.provider);

export const isCreateable = isCard;

export type BasicCreditCard = {
  creditCardNumber: string;
  cvv: string;
  expirationMonth: string;
  expirationYear: string;
  zipCode: string;
  countryCode: Code;
};

export type AddressFields = {
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  state: string;
};

export const CREDIT_CARD_FIELDS: {
  Basic: (keyof BasicCreditCard)[];
  Address: (keyof AddressFields)[];
} = {
  Basic: ['creditCardNumber', 'cvv', 'expirationMonth', 'expirationYear', 'zipCode', 'countryCode'],
  Address: ['streetAddress', 'city', 'state'],
};

export type CreditCardWithAddress = BasicCreditCard & AddressFields;

export type CreditCard = BasicCreditCard | CreditCardWithAddress;

export enum Country {
  US = 'US',
  CA = 'CA',
}

export enum DigitalWalletType {
  GooglePay = 'GooglePay',
  ApplePay = 'ApplePay',
}

export type OloPayData = DigitalWalletPaymentData & {
  cvv?: string;
  countryCode: Code;
  expirationMonth: string;
  expirationYear: string;
  isDigitalWallet: boolean;
  setDefault?: boolean;
};

export type DigitalWalletPaymentData = {
  cardLastFour: string;
  cardType: string;
  city?: string;
  countryCode: Code;
  saveCreditCard: boolean;
  state?: string;
  streetAddress?: string | null;
  streetAddress2?: string | null;
  token: string;
  zipCode: string;
  schemeId: string;
};

export type OloPayCard = {
  paymentCard: OloPayData;
};

export type GiftCard = {
  giftCardNumber: string;
  giftCardPin?: string;
};

export type SavedCompCard = {
  description: string;
  amount: number;
};

export type BrandedCard = {
  brandedCardNumber: string;
  brandedCardSecurityCode: string;
  expirationMonth: string;
  expirationYear: string;
};

export const BRANDED_CARD_FIELDS = [
  'brandedCardNumber',
  'brandedCardSecurityCode',
  'expirationMonth',
  'expirationYear',
];

export const GIFT_CARD_FIELDS = ['giftCardNumber'];

export type CardFields =
  | typeof CREDIT_CARD_FIELDS.Basic
  | (typeof CREDIT_CARD_FIELDS.Basic & typeof CREDIT_CARD_FIELDS.Address)
  | typeof GIFT_CARD_FIELDS;

export type SaveableCreditCard = CreditCard & { saveCreditCard?: boolean; setDefault?: boolean };
export type SaveableGiftCard = GiftCard & { saveGiftCard?: boolean };
export type Card = CreditCard | GiftCard | SavedCompCard | BrandedCard;
export type SaveableBrandedCard = BrandedCard & { saveBrandedCard?: boolean };
export type SaveableCard = SaveableCreditCard | SaveableGiftCard | SaveableBrandedCard;

export const isCompCard = (card: Card): card is SavedCompCard => card.hasOwnProperty('amount');

export const isGiftCard = (card: Card): card is GiftCard => card.hasOwnProperty('giftCardNumber');

export const isCreditCard = (card: Card): card is CreditCard =>
  card.hasOwnProperty('creditCardNumber');

export const isBrandedCard = (card: Card): card is BrandedCard =>
  card.hasOwnProperty('brandedCardNumber');

export const isCreditCardWithAddress = (card?: Card): card is CreditCardWithAddress =>
  isSome(card) && card.hasOwnProperty('streetAddress');

export const getPaymentMethod = (
  method: Method,
  memberships?: { id: string; isGiftCard?: boolean; isBrandedCard?: boolean }[]
) => {
  if (method.variant === Variant.Cash || method.variant === Variant.LevelUp) {
    return method.variant;
  }
  if (method.variant === Variant.CustomPassThrough) {
    return 'Custom PassThrough';
  }
  if (method.variant === Variant.NewCard) {
    if ('giftCardNumber' in method.paymentCard) {
      return 'Gift Card';
    }
    if ('brandedCardNumber' in method.paymentCard) {
      return 'Branded Card';
    }
    return 'Credit Card';
  }

  if (method.variant === Variant.CompCard) {
    // Actually impossible, but needed to make the below typesafe
    return 'Comp Card';
  }

  if (method.variant === Variant.Membership) {
    //when using billing scheme - look up by method's scheme id
    const membership = memberships?.find(
      m => m.id === method.schemeId || m.id === method.membershipId
    );
    if (membership?.isGiftCard) {
      return 'Gift Card';
    } else if (membership?.isBrandedCard) {
      return 'Branded Card';
    }
  }
  return 'Credit Card';
};

const Payment = { Provider, isGiftCard, isCreditCard };
export default Payment;
