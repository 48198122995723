import RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import WindowService from 'mobile-web/services/window';

import FeaturesService from './features';

type PathConfig = {
  cookieName: string;
  href: (params: Record<string, string | undefined>) => string;
  toName: string;
};

const CONFIGS: PathConfig[] = [
  {
    cookieName: 'olo-serve-next-licenses',
    href: () => '/open-source-licenses',
    toName: 'open-source-licenses',
  },
  {
    cookieName: 'srvnxtty',
    href: params => `/thank-you/${Object.values(params)[0]}`,
    toName: 'thank-you',
  },
  {
    cookieName: 'srvnxtlogin',
    href: () => '/login',
    toName: 'login',
  },
  {
    cookieName: 'srvnxtlocationsearch',
    href: () => '/search',
    toName: 'vendor-search-results',
  },
];

export default class MigrationService extends Service {
  // Service injections
  @service features!: FeaturesService;
  @service router!: RouterService;
  @service window!: WindowService;

  // Untracked properties

  // Tracked properties
  @tracked isRedirecting = false;

  // Getters and setters

  // Lifecycle methods
  constructor() {
    super(...arguments);

    this.router.on('routeWillChange', async transition => {
      for (const config of CONFIGS) {
        if (!this.serveNextDisabled) {
          if (
            !this.serveNextLocationSeachPageEnabled &&
            transition.to?.name === 'vendor-search-results'
          ) {
            continue;
          }
          if (
            transition.to?.name === config.toName &&
            document.cookie.includes(`${config.cookieName}=1`)
          ) {
            this.isRedirecting = true;

            const href = config.href(transition.to.params);
            this.window.location().assign(href);

            transition.abort();
          }
        }
      }
    });

    // This additional event handler is for when users land on the index page.
    // I found that the routeWillChange event handler above was not getting
    // instantiated until after routing in the app lifecycle, and thus it
    // would not fire in time to redirect users to the search page when landing
    // on the home page. I tried creating an instance-initializer, which worked
    // for the event handler, but then we did not yet have access to LD FF's,
    // so it was too early to know whether we wanted to redirect the user anyway.
    // Rather than messing with where we initialize LD FF data, I chose to add
    // this extra event handler, which will be called after both service instantiation
    // and FF instantiation in the app's life cycle.
    this.router.on('routeDidChange', async transition => {
      if (!this.serveNextDisabled && this.serveNextLocationSeachPageEnabled) {
        if (
          transition.to?.name === 'index' &&
          document.cookie.includes(`srvnxtlocationsearch=1`) &&
          this.skipHomePage
        ) {
          this.isRedirecting = true;

          const href = '/search';
          this.window.location().assign(href);

          transition.abort();
        }
      }
    });
  }

  // Other methods

  // Tasks

  // Actions and helpers
  get skipHomePage() {
    const variation = this.features.flags['abtest-new-location-search-page-olo-93790'] as string;
    return variation === 'B';
  }

  get serveNextDisabled() {
    return this.features.platformFlags['serve-next-disabled'];
  }
  get serveNextLocationSeachPageEnabled() {
    return this.features.platformFlags['serve-next-location-search'];
  }
}

declare module '@ember/service' {
  interface Registry {
    migration: MigrationService;
  }
}
