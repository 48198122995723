/* eslint-disable no-use-before-define */
import { assert } from '@ember/debug';

import { CustomField } from 'mobile-web/lib/custom-field';
import {
  SaveableCard,
  Provider,
  isCreditCard,
  SavedCompCard,
  CreditCard,
  GiftCard,
  OloPayData,
  isBrandedCard,
  DigitalWalletPaymentData,
} from 'mobile-web/lib/payment';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import BillingMembership, { CASH_MEMBERSHIP_ID } from 'mobile-web/models/billing-membership';
import BillingScheme, { BILLING_SCHEME_ID } from 'mobile-web/models/billing-scheme';

import capitalize from '../utilities/capitalize';

/**
 * We use this for the UI. The server doesn't actually care about its value.
 */
export enum Variant {
  Cash = 'Cash',
  CompCard = 'CompCard',
  NewCard = 'NewCard',
  Membership = 'Membership',
  LevelUp = 'LevelUp',
  OloPay = 'OloPay',
  CustomPassThrough = 'CustomPassThrough',
  DigitalWalletsSDK = 'DigitalWalletsSDK',
}

type Common = {
  /**
   * This is used in UI code only for display purposes.
   * It is required in the UI context, but optional otherwise because the server ignores it.
   */
  description?: string;

  amount: number;
  schemeId: string;
  canIncludeTip: boolean;
  includesTip: boolean;
  customFieldValues: CustomField[];
  hasManualEdit: boolean;
};

/**
 * I really hope I don't need to explain what Cash is to you
 */
export type Cash = Common & { variant: Variant.Cash; membershipId: typeof CASH_MEMBERSHIP_ID };

export type CustomPassThrough = Common & {
  variant: Variant.CustomPassThrough;
  membershipId: string;
  paymentCard: { schemeId: string };
};
/**
 * Represents a credit card or gift card added in this session that hasn't been saved yet
 */
export type NewCard = Common & {
  variant: Variant.NewCard;
  fromMembershipId?: EmberDataId;
  paymentCard: SaveableCard & { schemeId: string };
  /** Only present on gift cards */
  balance?: number;
};

/**
 * Represents a saved credit card or saved gift card
 */
export type Membership = Common & { variant: Variant.Membership; membershipId: string };

/**
 * Represents a saved Olo Pay credit card
 * The paymentCard property should only contain a cvv key with a string revalidation token.
 */
export type OloPayMembership = Membership & {
  paymentCard: Pick<OloPayData, 'cvv'>;
};

/**
 * A special snowflake of handling for LevelUp accounts. It needs special rendering logic.
 */
export type LevelUp = Common & { variant: Variant.LevelUp; membershipId: string };

/**
 * Represents a comp card, which the server treats as a loyalty discount but the user
 * expects to treat like a gift card.
 */
export type CompCard = Common & { variant: Variant.CompCard; balance: number };

/**
 * When Olo Pay is enabled, credit card fields are replaced with iframes from Stripe.
 * After we receive the `paymentMethod`, we pass a payment card with a `token` that is
 * used by payment webhooks to process the payment.
 */
export type OloPayMethod = Common & {
  variant: Variant.OloPay;
  paymentCard: OloPayData;
};

/**
 * Represents a digital wallet payment method.
 */
export type DigitalWalletMethod = Common & {
  variant: Variant.DigitalWalletsSDK;
  paymentCard: DigitalWalletPaymentData;
};

export function fromScheme(scheme: BillingScheme): Method {
  if (scheme.provider === Provider.PassThrough) {
    if (scheme.id === BILLING_SCHEME_ID.CASH) {
      return {
        variant: Variant.Cash,
        amount: 0,
        schemeId: scheme.id,
        canIncludeTip: false,
        includesTip: false,
        customFieldValues: [],
        membershipId: CASH_MEMBERSHIP_ID,
        description: scheme.description,
        hasManualEdit: false,
      };
    }
  }

  assert(
    `Only call fromScheme with schemes that have only one membership`,
    scheme.memberships.length === 1
  );

  return fromMembership(scheme.memberships.objectAt(0)!);
}

export function fromMembership(membership: BillingMembership): Method {
  const common: Common = {
    amount: 0,
    schemeId: membership.billingScheme.id,
    canIncludeTip: true,
    includesTip: false,
    customFieldValues: [],
    description: membership.description,
    hasManualEdit: false,
  };

  if (membership.billingScheme.provider === Provider.LevelUp) {
    return { ...common, variant: Variant.LevelUp, membershipId: membership.id };
  }
  return { ...common, variant: Variant.Membership, membershipId: membership.id };
}

export function fromCompCard(card: SavedCompCard): CompCard {
  return {
    amount: card.amount,
    balance: card.amount,
    schemeId: '',
    description: card.description,
    canIncludeTip: false,
    includesTip: false,
    customFieldValues: [],
    hasManualEdit: false,
    variant: Variant.CompCard,
  };
}

export function fromOloPay(oloPayData: OloPayData): OloPayMethod {
  const { cardType, cardLastFour } = oloPayData;
  const description = `${capitalize(cardType)} x-${cardLastFour}`;

  return {
    variant: Variant.OloPay,
    description,
    amount: 0,
    canIncludeTip: true,
    includesTip: false,
    customFieldValues: [],
    hasManualEdit: false,
    schemeId: oloPayData.schemeId,
    paymentCard: { ...oloPayData },
  };
}

export function fromDigitalWalletData(
  digitalWalletPaymentData: DigitalWalletPaymentData
): DigitalWalletMethod {
  const { cardType, cardLastFour } = digitalWalletPaymentData;
  const description = `${capitalize(cardType)} x-${cardLastFour}`;

  return {
    variant: Variant.DigitalWalletsSDK,
    description,
    amount: 0,
    canIncludeTip: true,
    includesTip: false,
    customFieldValues: [],
    hasManualEdit: false,
    schemeId: digitalWalletPaymentData.schemeId,
    paymentCard: { ...digitalWalletPaymentData },
  };
}

export function fromCard(
  card: CreditCard | GiftCard,
  scheme: BillingScheme,
  membership?: BillingMembership
): NewCard {
  const description = isCreditCard(card)
    ? `x-${card.creditCardNumber.slice(-4)}`
    : isBrandedCard(card)
    ? `${scheme.description} x${card.brandedCardNumber.slice(-4)}`
    : `${scheme.description} x${card.giftCardNumber.slice(-4)}`;
  const schemeId = scheme.id;
  const newCard: NewCard = {
    amount: 0,
    schemeId,
    canIncludeTip: true,
    includesTip: false,
    customFieldValues: [],
    variant: Variant.NewCard,
    description,
    paymentCard: { ...card, schemeId },
    hasManualEdit: false,
  };

  if (isSome(membership)) {
    newCard.fromMembershipId = membership.id;
  }

  return newCard;
}

export function fromCustomPassThrough(scheme: BillingScheme): CustomPassThrough {
  const custom: CustomPassThrough = {
    variant: Variant.CustomPassThrough,
    amount: 0,
    schemeId: scheme.id,
    canIncludeTip: false,
    includesTip: false,
    customFieldValues: [],
    membershipId: '',
    description: scheme.description,
    paymentCard: { schemeId: scheme.id },
    hasManualEdit: false,
  };
  return custom;
}

export const isMembership = (method: Method): method is Membership =>
  method.hasOwnProperty('membershipId');

export const isDefaultable = (method: Method): method is NewCard | OloPayMethod =>
  'paymentCard' in method && 'setDefault' in method.paymentCard;

export const isDigitalWallet = (method: Method): boolean =>
  method.variant === Variant.DigitalWalletsSDK ||
  (method.variant === Variant.OloPay && method.paymentCard.isDigitalWallet);

/** A server-friendly representation of a user's selected payment source.

  A `Method` includes all information necessary for the server to process a
  payment, including whether the payment method in question has a tip attached
  */
export type Method =
  | Cash
  | NewCard
  | Membership
  | LevelUp
  | CompCard
  | OloPayMethod
  | CustomPassThrough
  | OloPayMembership
  | DigitalWalletMethod;
export const Method = { CASH_MEMBERSHIP_ID, fromScheme };
export default Method;
